<template>
    <div>
        <!-- 大图 -->
        <img :src="bigImg" width="100%" height="450px" />
        <div class="message_list">
            <!-- 主体部分 -->
            <div class="main_box">
                <!-- 左边 -->
                <div class="left_box">
                    <div class="title">{{ title }}</div>
                    <!-- 做循环 -->
                    <div class="item" :class="{ active_item: activeIndex == item.text }" v-for="(item, index) in leftList" :key="index" @click="changeIndex(item.text, index)">
                        <p class="left_space" :style="{ 'background': activeIndex == item.text ? '#000' : '#999' }"></p>
                        <span>{{ item.text }}</span>
                    </div>
                </div>
                <!-- 右边 -->
                <!-- <div class="right_box">
                    <div class="title">
                        <span style="float: left; font-size: 24px; color: #1158e8">{{ activeIndex }}</span>
                        <breadcrumb :pathList="pathList" style="float: right; color: #333333; font-size: 14px" v-if="hideBreadCrumb == 0"></breadcrumb>
                    </div>
                    <slot name="right"> </slot>
                </div> -->
                <div class="right_box">
                    <slot name="right"> </slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        // 大图图片
        bigImg: {
            type: String,
            default() {
                return ''
            },
        },
        title: {
            type: String,
            default() {
                return ''
            },
        },
        leftList: {
            type: Array,
            default() {
                return []
            },
        },
        action: {
            type: String,
        },
        hideBreadCrumb: {
            type: String,
            default() {
                return '0'
            },
        },
    },
    data() {
        return {
            activeIndex: '',
            pathList: [],
        }
    },
    mounted() {
        if (this.action) {
            this.activeIndex = this.leftList[this.action].text
        } else {
            this.activeIndex = this.leftList.length != 0 && this.leftList[this.$route.query.active || 0].text
        }
        this.pathList = [
            { name: this.title, path: this.$route.path + '?active=0' },
            { name: this.activeIndex, path: this.$route.path + '?active=' + this.$route.query.active },
        ]
    },
    methods: {
        changeIndex(text, index) {
            this.$emit('changeIndex', text, index)
        },
    },
    watch: {
        $route() {
            if (this.action) {
                this.activeIndex = this.leftList[this.action].text
            } else {
                this.activeIndex = this.leftList.length != 0 && this.leftList[this.$route.query.active || 0].text
            }
            this.pathList = [
                { name: this.title, path: this.$route.path + '?active=0' },
                { name: this.activeIndex, path: this.$route.path + '?active=' + this.$route.query.active },
            ]
        },
    },
}
</script>

<style lang="less" scoped>
.message_list {
    margin-top: 100px;
    .main_box {
        position: relative;
        width: 1200px;
        margin: 0 auto 137px;
        overflow: hidden;
        .left_box {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            border-right: 1px solid #ccc;
            // padding-right: 76px;
            width: 200px;
            .title {
                color: #000;
                font-size: 26px;
                font-weight: bold;
                text-align: left;
                margin-bottom: 10px;
            }
            .item {
                box-sizing: border-box;
                margin-left: 6px;
                height: 40px;
                line-height: 40px;
                color: #999999;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                .left_space {
                    width: 16px;
                    height: 1px;
                    margin-right: 12px;
                }
            }
            .active_item {
                color: #000;
            }
        }
        .right_box {
            box-sizing: border-box;
            margin-left: 200px;
            height: auto;
            min-height: 730px;
            width: 1000px;
            .title {
                font-size: 30px;
                font-family: Alibaba PuHuiTi;
                font-weight: bold;
                margin-bottom: 10px;
                // border-bottom: 1px dashed #ccc;
            }
            .subtitle {
                font-size: 16px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #999999;
                margin-bottom: 40px;
            }
        }
    }
}
</style>
