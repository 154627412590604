import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// 解决多次点击路由报错的问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const routes = [
    { path: '/', redirect: '/index/home' },
    { path: '/login', name: 'login', component: () => import('../views/login.vue') },
    {
        path: '/index',
        name: 'index',
        component: () => import('../views/index.vue'),
        children: [
            { path: '/index/home', name: '官网首页', component: () => import('../views/index/home.vue') },
            // 关于我们
            { path: '/index/aboutUs', name: '关于我们', component: () => import('../views/index/aboutUs.vue') },
            // 联系我们
            { path: '/index/contactUs', name: '联系我们', component: () => import('../views/contact/contactUs.vue') },
            // 软件产品
            { path: '/index/product', name: '软件产品', component: () => import('../views/product/product.vue') },
            { path: '/index/decoctionPieces', name: '中药饮片', component: () => import('../views/product/decoctionPieces.vue') },
            { path: '/index/instruments', name: '医疗器械', component: () => import('../views/product/instruments.vue') },
            // 业务流程
            { path: '/index/flowPath', name: '业务流程', component: () => import('../views/flowPath/flowPath.vue') },
            // 相关服务
            { path: '/index/service', name: '相关服务', component: () => import('../views/index/service.vue') },
            // 行业资讯
            { path: '/index/message', name: '行业资讯', component: () => import('../views/message/message_list.vue') },
            { path: '/index/message/detail', name: '资讯详情', component: () => import('../views/message/message_detail.vue') },
            { path: '/index/message/addArticle', name: '新增文章', component: () => import('../views/message/add_article.vue') },
            { path: '/index/message/editArticle', name: '编辑文章', component: () => import('../views/message/edit_article.vue') },
            // 数字经济
            { path: '/index/digital', name: '数字经济', component: () => import('../views/digital/digital_list.vue') },
            { path: '/index/digital/detail', name: '资讯详情', component: () => import('../views/digital/digital_detail.vue') },
        ]
    },
    // 文章管理
    { path: '/index/articleManage', name: 'index', component: () => import('../views/message/article_manage.vue') }
]

const router = new VueRouter({
    routes
})

router.beforeEach((to, from, next) => {
    if (to.path == '/index/articleManage') {
        // if (sessionStorage.getItem('userInfo') && JSON.parse(sessionStorage.getItem('userInfo')).password == 'fzygsz2020') {
            next()
        // } else {
            // next('/login')
        // }
    } else {
        next()
    }
})

export default router
