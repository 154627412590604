<template>
  <div>
    <div id="editor" ref="editor" class="editor" style="z-index: 1000;">

    </div>
    <!-- <div ref="desc" class="desc"></div> -->
  </div>
</template>

<script>
  let that
  let editor
  import E from '../util/wangEditor.min.js'
  export default {
    props: {
      content: {
        type: String
      }
    },
    data () {
      return {
        editor: {},
        html: ''
      }
    },
    mounted () {
      that = this
      //生成富文本编辑器
      editor = new E(this.$refs.editor)
      this.editor = editor
      editor.config.onchange = function (newHtml) {
        // html = this.editor.txt.html()
        that.html = newHtml
        that.input_editor(newHtml)
        // that.$refs.desc.innerHTML = newHtml
      }
      editor.config.uploadImgServer = 'https://www.fzygsz.com:5000/api/cos/upload'
      editor.config.uploadImgMaxSize = 3 * 1024 * 1024  //图片大小
      editor.config.uploadImgAccept = ["jpg", "jpeg", "png", "gif", "bmp"]
      editor.config.uploadFileName = "file"
      editor.config.uploadImgHeaders = {
          "token": 'admin:0e22b40876914940',
      }
      editor.config.uploadImgMaxLength = 5  //最大数量
      // editor.config.uploadFileName = 'uploadFile'
      let that = this
      editor.config.uploadImgHooks = {
          before: function (xhr, editor, files) {
              // 图片上传之前触发
              // xhr 是 XMLHttpRequst 对象，editor 是编辑器对象，files 是选择的图片文件
              // 如果返回的结果是 {prevent: true, msg: 'xxxx'} 则表示用户放弃上传
              // return {
              //     prevent: true,
              //     msg: '放弃上传'
              // }
          },
          success: function (xhr, editor, result) {
              // 图片上传并返回结果，图片插入成功之后触发
              // xhr 是 XMLHttpRequst 对象，editor 是编辑器对象，result 是服务器端返回的结果
              // insertImg
          },
          fail: function (xhr, editor, result) {
              // 图片上传并返回结果，但图片插入错误时触发
              // xhr 是 XMLHttpRequst 对象，editor 是编辑器对象，result 是服务器端返回的结果
          },
          error: function (xhr, editor) {
              // 图片上传出错时触发
              // xhr 是 XMLHttpRequst 对象，editor 是编辑器对象

          },
          timeout: function (xhr, editor) {
              // 图片上传超时时触发
              // xhr 是 XMLHttpRequst 对象，editor 是编辑器对象
          },

          // 如果服务器端返回的不是 {errno:0, data: [...]} 这种格式，可使用该配置
          // （但是，服务器端返回的必须是一个 JSON 格式字符串！！！否则会报错）
          customInsert: function (insertImg, result, editor) {
              // 图片上传并返回结果，自定义插入图片的事件（而不是编辑器自动插入图片！！！）
              // insertImg 是插入图片的函数，editor 是编辑器对象，result 是服务器端返回的结果

              // 举例：假如上传图片成功后，服务器端返回的是 {url:'....'} 这种格式，即可这样插入图片：
              // var url = result.url
              insertImg(result.msg)
              // result 必须是一个 JSON 格式字符串！！！否则报错
          }
      }
      // 创建实例
      editor.create()
    },
    methods: {
      get_html () {
        return this.html
      },
      input_editor (html) {
        this.$emit('input_editor', html)
      }
    },
    watch: {
      content () {
        this.editor.txt.html(this.content)
      }
    }
  }
</script>

<style lang="less" scoped>
  .article_detail_box {
    width: 1200px;
    margin: 0 auto;
  }

</style>
<style>
  .w-e-text-container {
    z-index: 999!important;
    height: 700px !important;
  }
  .w-e-toolbar {
    z-index: 1000!important;
  }
</style>
