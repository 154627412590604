<template>
    <div class="footer">
        <div class="modular">
            <ul class="cursorWrap">
                <li style="font-size: 20px; margin-bottom: 30px">关于我们</li>
                <li @click="jump('企业简介')">企业简介</li>
                <!-- <li @click="jump('我们的优势')">我们的优势</li> -->
                <li @click="jump('企业使命')">企业使命</li>
                <li @click="jump('软件服务')">软件服务</li>
                <li @click="jump('企业文化')">企业文化</li>
                <li @click="jump('发展历程')">发展历程</li>
                <li>
                    <div class="wm">
                        <el-popover placement="top" width="30" trigger="hover">
                            <img style="width: 150px; height: 150px" src="../assets/image/android.png" alt="" />
                            <div style="height: 30px; width: 30px" class="anzhuo" slot="reference"></div>
                        </el-popover>
                        <el-popover placement="top" width="30" trigger="hover">
                            <img style="width: 150px; height: 150px" src="../assets/image/ios.png" alt="" />
                            <div style="height: 30px; width: 30px" class="ios" slot="reference"></div>
                        </el-popover>
                        <el-popover placement="top" width="30" trigger="hover">
                            <img style="width: 150px; height: 150px" src="../assets/image/small_program.jpg" alt="" />
                            <div style="height: 30px; width: 30px" class="gongzonghao" slot="reference"></div>
                        </el-popover>
                        <el-popover placement="top" width="30" trigger="hover">
                            <img style="width: 150px; height: 150px" src="../assets/image/company_wechat.png" alt="" />
                            <div style="height: 30px; width: 30px" class="qiyeweixin" slot="reference"></div>
                        </el-popover>
                    </div>
                </li>
            </ul>
            <ul class="cursorWrap">
                <li style="font-size: 20px; margin-bottom: 30px">软件产品</li>
                <li @click="jump('药品销售管理系统')">药品销售管理系统</li>
                <!-- <li @click="jump('中药饮片销售管理系统')">中药饮片销售管理系统</li> -->
                <li @click="jump('医疗器械销售管理系统')">医疗器械销售管理系统</li>
            </ul>
            <!-- <ul class="cursorWrap">
                <li style="font-size: 20px; margin-bottom: 30px">业务流程</li>
                <li @click="jump('业务流程')">诉求沟通</li>
                <li @click="jump('业务流程')">项目定义</li>
                <li @click="jump('业务流程')">云基域名</li>
                <li @click="jump('业务流程')">培训交付</li>
                <li @click="jump('业务流程')">售后服务</li>
            </ul> -->
            <ul class="cursorWrap">
                <li style="font-size: 20px; margin-bottom: 30px">相关服务</li>
                <li @click="jump('软件实施服务')">软件实施服务</li>
                <li @click="jump('系统运维服务')">系统运维服务</li>
                <li @click="jump('定制开发服务')">定制开发服务</li>
            </ul>
            <ul class="cursorWrap" style="width: 200px">
                <li style="font-size: 20px; margin-bottom: 30px">联系我们</li>
                <!-- <li style="display: flex; justify-content: space-between; width: 350px">
                    <div>电话：0591-83852927</div>
                    <div>传真：0591-88203961</div>
                </li>
                <li style="display: flex; justify-content: space-betweenx; width: 350px">
                    <div style="width: 63px">手机：</div>
                    <div style="display: flex; justify-content: space-between; flex-wrap: wrap">
                        <div style="margin-bottom: 10px">13705975869 王先生</div>
                        <div style="margin-bottom: 10px">18505917896 陈先生</div>
                        <div style="margin-bottom: 10px">13615032680 余女士</div>
                        <div style="margin-bottom: 10px">15205041110 陈先生</div>
                    </div>
                </li> -->
                <li>电话：0591-83852927</li>
                <li>传真：0591-88203961</li>
                <li style="display: flex; justify-content: space-betweenx; width: 230px">
                    <div style="width: 84px">手机：</div>
                    <div style="display: flex; justify-content: space-between; flex-wrap: wrap">
                        <div style="margin-bottom: 15px">13705975869 王先生</div>
                        <div style="margin-bottom: 15px">18505917896 陈先生</div>
                        <div style="margin-bottom: 15px">13615032680 余女士</div>
                        <div style="margin-bottom: 15px">15205041110 陈先生</div>
                    </div>
                </li>
                <li>地址：福建省福州市闽侯县上街镇中青大厦17层1706（地铁2号线厚庭站 B出口往前100米）</li>
            </ul>
            <div class="map" @click="jumpContact"></div>
        </div>
        <div class="copyright" style="margin-bottom: 30px; color: #939393; font-size: 14px">Copyright © 2020-{{ new Date().getFullYear() }} 福州云格数字科技有限公司</div>
        <div class="copyright" style="cursor: pointer"><a style="color: #939393; font-size: 14px" href="https://beian.miit.gov.cn/" target="_blank">闽ICP备2020017015号-2</a></div>
    </div>
</template>

<script type="text/ecmascript-6">
export default {
    components: {},
    data() {
        return {}
    },
    created() {},
    mounted() {},
    methods: {
        jumpContact() {
            this.$router.push({ path: '/index/contactUs' })
        },
        jump(key) {
            switch (key) {
                case '企业简介':
                    this.$router.push({ path: '/index/aboutUs?active=0' })
                    break
                case '我们的优势':
                    this.$router.push({ path: '/index/aboutUs?active=1' })
                    break
                case '企业使命':
                    this.$router.push({ path: '/index/aboutUs?active=1' })
                    break
                case '软件服务':
                    this.$router.push({ path: '/index/aboutUs?active=2' })
                    break
                case '企业文化':
                    this.$router.push({ path: '/index/aboutUs?active=3' })
                    break
                case '发展历程':
                    this.$router.push({ path: '/index/aboutUs?active=4' })
                    break
                case '软件实施服务':
                    this.$router.push({ path: '/index/service?active=0' })
                    break
                case '系统运维服务':
                    this.$router.push({ path: '/index/service?active=1' })
                    break
                case '定制开发服务':
                    this.$router.push({ path: '/index/service?active=2' })
                    break
                case '药品销售管理系统':
                    this.$router.push({ path: '/index/product?active=0' })
                    break
                case '中药饮片销售管理系统':
                    this.$router.push({ path: '/index/decoctionPieces?active=0' })
                    break
                case '医疗器械销售管理系统':
                    this.$router.push({ path: '/index/instruments?active=0' })
                    break
                case '业务流程':
                    this.$router.push({ path: '/index/flowPath' })
                    break

                default:
                    break
            }
            document.body.scrollTop = 0
            document.documentElement.scrollTop = 0
        },
    },
    watch: {},
}
</script>

<style lang="less" scoped>
a {
    text-decoration: none;
    color: #fff;
}
.footer {
    position: relative;
    width: 100%;
    background: rgb(37, 37, 37);
    .modular {
        position: relative;
        display: flex;
        justify-content: space-between;

        padding: 40px 20px;
        height: 480px;

        color: #fff;
        font-size: 13px;
        .map {
            position: absolute;
            top: 45%;
            left: 50%;
            width: 50%;
            transform: translateX(-50%);
            height: 150px;
            background: url('../assets/image/map1.png') no-repeat;
            background-size: 100% 100%;
            cursor: pointer;
        }
        .cursorWrap li:not(:first-child) {
            margin-bottom: 15px;
            cursor: pointer;
            font-size: 12px;
            color: #9b9ea0;
        }
        .cursorWrap li:nth-child(1) {
            font-size: 14px;
            color: #d7d8d9;
        }
        ul li:not(:first-child) {
            margin-bottom: 15px;
        }
    }
}
.copyright {
    position: absolute;
    z-index: 99;
    color: #fff;
    font-size: 14px;
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%);
}
.wm {
    position: absolute;

    bottom: 18%;
    left: 0;
    width: 200px;
    height: 100px;
    display: flex;
    justify-content: space-around;
    .anzhuo {
        background: url('../assets/image/icon/anzhuo.png') no-repeat;
    }
    .ios {
        background: url('../assets/image/icon/ios.png') no-repeat;
    }
    .gongzonghao {
        background: url('../assets/image/icon/gongzonghao.png') no-repeat;
    }
    .qiyeweixin {
        background: url('../assets/image/icon/qiyeweixin.png') no-repeat;
    }
    .anzhuo:hover {
        background: url('../assets/image/icon/anzhuo1.png') no-repeat;
    }
    .ios:hover {
        background: url('../assets/image/icon/ios1.png') no-repeat;
    }
    .gongzonghao:hover {
        background: url('../assets/image/icon/gongzonghao1.png') no-repeat;
    }
    .qiyeweixin:hover {
        background: url('../assets/image/icon/qiyeweixin1.png') no-repeat;
    }
    img {
        width: 100px;
        height: 100px;
    }
}
</style>
