import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/base.css' // 公共样式
import './assets/css/animal.css'
import './plugins/element.js'

Vue.config.productionTip = false

import articleIndex from '@/components/article_index.vue'
import navigationBar from '@/components/navigationBar.vue'
import footerBar from '@/components/footerBar.vue'
import breadcrumb from '@/components/breadcrumb.vue'
import editor from '@/components/editor.vue'
import NewForm from '@/components/NewForm.vue'

Vue.component('NewForm', NewForm)
Vue.component('articleIndex', articleIndex)
Vue.component('navigationBar', navigationBar)
Vue.component('footerBar', footerBar)
Vue.component('breadcrumb', breadcrumb)
Vue.component('editor', editor)

// vue页面跳转后返回原页面初始位置
// router.afterEach((to, from, next) => {
//     document.body.scrollTop = 0
//     document.documentElement.scrollTop = 0
// })

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
