<template>
  <div style="color: #333333;font-size: 14px;">
    您的当前位置：
    <span v-for="(item, index) in pathList" :key="index">
      <span style="cursor: pointer;" :style="{color: (item.path && index != pathList.length-1) ? '#1158E8' : '#333' }" @click="goNav(item.path, index)">{{item.name}}</span> /
    </span>
  </div>
</template>

<script>
  export default {
    props: {
      pathList: {
        type: Array,
        default () {
          return []
        }
      }
    },
    methods: {
      goNav (path, index) {
        if (index == this.pathList.length-1) {
          return 0
        }
        if (path) {
          this.$router.push({
            path
          })
        }
      }
    }
  }
</script>

<style>
</style>
