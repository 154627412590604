<template>
    <div class="">
        <div class="header">
            <div class="modular">
                <!-- logo -->
                <img @click="toHome" style="cursor: pointer" src="../assets/image/logo.png" alt="" srcset="" />
                <!-- 导航栏 -->
                <!-- <ul class="navigationBar">
                    <li class="homePage" :class="{ active: current == 1 }" @click="homePage">首页</li>
                    <li class="aboutUs" :class="{ active: current == 2 }" @click="aboutUs">关于我们</li>
                    <li class="poduct" :class="{ active: current == 3 }" @click="poduct">软件产品</li>
                    <li class="service" :class="{ active: current == 4 }" @click="service">相关服务</li>
                    <li class="flowPath" :class="{ active: current == 5 }" @click="flowPath">业务流程</li>
                    <li class="information" :class="{ active: current == 6 }" @click="information">行业资讯</li>
                    <li class="economy" :class="{ active: current == 7 }" @click="economy">数字经济</li>
                    <li class="contactUs" :class="{ active: current == 8 }" @click="contactUs">联系我们</li>
                </ul> -->
                <div class="navigationBar">
                    <el-menu :default-active="myPath" class="el-menu-demo" mode="horizontal" @select="handleSelect" exact router>
                        <el-menu-item index="/index/home">首页</el-menu-item>
                        <el-submenu index="3">
                            <template slot="title">软件产品</template>
                            <el-menu-item index="/index/product?active=0">药品销售管理系统</el-menu-item>
                            <!-- <el-menu-item index="/index/decoctionPieces?active=0">中药饮片销售管理系统</el-menu-item> -->
                            <el-menu-item index="/index/instruments?active=0">医疗器械销售管理系统</el-menu-item>
                        </el-submenu>
                        <el-submenu index="/index/service">
                            <template slot="title">相关服务</template>
                            <el-menu-item index="/index/service?active=0">软件实施服务</el-menu-item>
                            <el-menu-item index="/index/service?active=1">系统运维服务</el-menu-item>
                            <el-menu-item index="/index/service?active=2">定制开发服务</el-menu-item>
                        </el-submenu>
                        <!-- <el-menu-item index="/index/flowPath">业务流程</el-menu-item> -->
                        <el-submenu index="/index/message">
                            <template slot="title">行业资讯</template>
                            <el-menu-item index="/index/message?active=0">软件行业</el-menu-item>
                            <el-menu-item index="/index/message?active=1">医药行业</el-menu-item>
                        </el-submenu>
                        <el-menu-item index="/index/digital">数字经济</el-menu-item>
                        <el-submenu index="/index/aboutUs">
                            <template slot="title">关于我们</template>
                            <el-menu-item index="/index/aboutUs?active=0">企业简介</el-menu-item>
                            <!-- <el-menu-item index="/index/aboutUs?active=1">我们的优势</el-menu-item> -->
                            <el-menu-item index="/index/aboutUs?active=1">企业使命</el-menu-item>
                            <el-menu-item index="/index/aboutUs?active=2">软件服务</el-menu-item>
                            <el-menu-item index="/index/aboutUs?active=3">企业文化</el-menu-item>
                            <el-menu-item index="/index/aboutUs?active=4">发展历程</el-menu-item>
                        </el-submenu>
                        <el-menu-item index="/index/contactUs">联系我们</el-menu-item>
                    </el-menu>
                </div>
                <!-- <div class="management" @click="toManagement">管理后台</div> -->
            </div>
        </div>
    </div>
</template>

<script type="text/ecmascript-6">
export default {
    components: {},
    data() {
        return {
            myPath: '',
        }
    },
    created() {
        this.myPath = '/' + this.$route.path.split('/')[1] + '/' + this.$route.path.split('/')[2] + (this.$route.query.active ? `?active=${this.$route.query.active}` : '')
    },
    mounted() {},
    methods: {
        handleSelect(key, keyPath) {
            // console.log(key, keyPath)
        },
        toHome() {
            this.$router.push({ path: '/index/home' })
        },
        toManagement() {
            if (sessionStorage.getItem('userInfo') && JSON.parse(sessionStorage.getItem('userInfo')).password == 'fzygsz2020') {
                this.$router.push({ path: '/index/articleManage' })
            } else {
                this.$router.push({ path: '/login' })
            }
        },
    },
    watch: {
        $route: function (to, from) {
            this.myPath = '/' + this.$route.path.split('/')[1] + '/' + this.$route.path.split('/')[2] + (this.$route.query.active ? '?active=' + this.$route.query.active : '')
        },
    },
}
</script>

<style lang="less" scoped>
.header {
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background: #fff;
    z-index: 100;
    box-shadow: 0 1px 10px 0 rgb(0 0 0 / 10%);
    .modular {
        width: 1200px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        height: 90px;
        .el-menu-demo {
            border-bottom: solid 0px #e6e6e6 !important;
            height: 100%;
        }
        .management {
            width: 120px;
            height: 38px;
            color: #fff;
            background-color: #1158E8;
            text-align: center;
            line-height: 38px;
            font-size: 14px;
            border-radius: 6px;
            cursor: pointer;
        }
    }
}
</style>
<style lang="less">
.header {
    .el-menu-item {
        color: #000;
        height: 90px;
        line-height: 90px;
        padding: 0 35px;
    }
    .el-menu--horizontal>.el-menu-item.is-active {
        background: #1158E8;
        color: #fff;
        border-bottom: none;
    }
    .el-menu--horizontal>.el-submenu .el-submenu__title {
        color: #000;
        height: 90px;
        line-height: 90px;
    }
    .el-menu--horizontal>.el-submenu.is-active .el-submenu__title {
        background: #1158E8;
        color: #fff !important;
        border-bottom: none;
    }
    .el-submenu__title i {
        color: #000;
    }
    .is-active .el-submenu__title i {
        color: #fff;
    }
}
</style>
