<template>
  <div class="NewForm">
    <el-form class="form" :inline="true" ref="form" :rules="rules" :optionData="optionData" :model="data"
      :hide-required-asterisk="true">
      <el-form-item v-for="(item, index) in formSettingsData" :key="index" :label="item.label" :prop="item.prop" class="itemForm"
        :title="data[item.prop]" :style="{ width: item.width || '33%' }">
        <template v-if="item.type == 'slot'">
          <slot :name="item.prop" :item="item" />
        </template>
        <template v-if="item.type == 'input'">
          <el-input clearable v-model.trim="data[item.prop]" v-if="!item.readonly" :placeholder="item.placeholder || '请输入' + item.label.slice(0, item.label.length - 1)" />
          <el-input clearable v-model.trim="data[item.prop]" readonly v-else />
        </template>
        <template v-if="item.type == 'select'">
          <el-select v-if="!item.readonly" clearable filterable v-model="data[item.prop]">
            <el-option v-for="(select_item, select_index) in optionData[item.prop]" :key="select_index" :label="select_item.label"
              :value="select_item.value">
            </el-option>
          </el-select>
          <el-input clearable v-model.trim="data[item.prop]" readonly v-else />
          <!-- {{data[item.prop]}} -->
        </template>

        <template v-if="item.type == 'upload'">
          <img :src="data[imgProp]" alt="" style="height: 100px;width: 150px;margin-bottom: 30px;">
          <el-upload v-show="!item.readonly" :drag="true" class="upload-demo" action="https://www.fzygsz.com:5000/api/cos/upload"
            :multiple="true" :on-success="handelSuccess" :show-file-list="false" :headers="headers" list-type="picture-card">
            <div class="uploadBtn">上传图片</div>
          </el-upload>
        </template>
      </el-form-item>
    </el-form>
  </div>
</template>
<script type="text/ecmascript-6">
  export default {
    props: {
      formSettingsData: {
        type: Array,
        required: true,
      }, //表单的配置信息数据  [{ label: '客户名称:', prop: 'customerName', type: 'input', placeholder: '输入客户名称' }]
      data: {
        type: Object,
        required: true,
      }, //表单数据对象   {}
      optionData: {
        type: Object,
        default: () => {
          return {}
        },
      },
      imgProp: {
        type: String
      }
    },
    data() {
      return {
        rules: {},
        headers: {
          // token: JSON.parse(sessionStorage.getItem('token')),
        },
      }
    },
    created() {
      // 数量(次数)
      var number = (rule, value, callback) => {
        var reg = /^[0-9]*$/
        var regExp = new RegExp(reg)
        if (value && value.toString().length > 10) return callback(new Error('数值长度不能超过10'))
        if (value && !regExp.test(value)) return callback(new Error('请输入大于等于零的正整数'))
        callback()
      }
      // 密码校验
      var passWord = (rule, value, callback) => {
        var reg = /^[0-9A-Za-z\\W_!?.,/';@#$%^&*`~()-+=]{6,20}$/
        var regExp = new RegExp(reg)
        if (value && !regExp.test(value)) return callback(new Error('密码包含数字、英文或者字符，长度为6-20'))
        callback()
      }

      this.formSettingsData.forEach((x) => {
        var arr = x.rulesData && x.rulesData.split(' ')
        var val = x.rulesData && x.rulesData.split(' ')[0] // 需要校验的类型
        var folg = x.rulesData ? x.rulesData.split(' ')[1] : '' // 是否必填
        switch (val) {
          case 'must': // 不需其他要校验的必填字段
            this.rules[x.prop] = {
              required: true,
              message: `${x.label.slice(0, x.label.length - 1)}不能为空`,
              trigger: ['blur', 'change']
            }
            break
          case 'number':
            this.rules[x.prop] =
              folg == 1 ? [{
                  required: true,
                  message: `${x.label.slice(0, x.label.length - 1)}不能为空`,
                  trigger: ['blur', 'change']
                },
                {
                  validator: number,
                  trigger: ['blur', 'change']
                },
              ] : {
                validator: number,
                trigger: ['blur', 'change']
              }
            break
          case 'passWord':
            this.rules[x.prop] =
              folg == 1 ? [{
                  required: true,
                  message: `${x.label.slice(0, x.label.length - 1)}不能为空`,
                  trigger: ['blur', 'change']
                },
                {
                  validator: passWord,
                  trigger: ['blur', 'change']
                },
              ] : {
                validator: passWord,
                trigger: ['blur', 'change']
              }
            break
        }
      })
    },
    methods: {
      // 校验数据
      validateForm() {
        let flag = null
        this.$refs['form'].validate((valid) => (flag = valid ? true : false))
        return flag
      },
      // 重置
      resetForm() {
        this.$refs['form'].resetFields()
      },
      // 上传图片成功
      handelSuccess(file, fileList) {
        this.data[this.imgProp] = file.msg
        this.$forceUpdate()
        this.$emit('handelSuccess')
      },
      //删除图片
      delImg(index) {
        // console.log(index)
      }
    }
  }
</script>

<style>
  .upload-demo {
    margin-top: -35px;
    width: 150px;
    height: 30px;
  }

  .el-upload-dragger,
  .uploadBtn,
  .el-upload--picture-card {
    width: 100%;
    height: 100%;
    border: none;
    line-height: 30px;
    background-color: #1158e8;
    color: #fff;
  }
  .el-form-item__label {
    width: 75px;
  }
</style>
